import React from 'react';
import { infoMenus } from '../ref/refINFO';
import ViewBuild from '../view/ViewBuild';
import ViewBase from '../view/ViewBase';

const PageBuild = () => {

    //----------------------------------------------------------------- Working Function Area Start
    //----------------------------------------------------------------- Working Function Area End

    //----------------------------------------------------------------- useEffect Area Start
    //----------------------------------------------------------------- useEffect Area End

    //----------------------------------------------------------------- Header Button Area Start
    //----------------------------------------------------------------- Header Button Area End
    const propDatas = {
        curPath: infoMenus[2].path,
    }

    return (
        <>
            <ViewBase {...propDatas} ViewContainer={ViewBuild}/>
        </>
    );
}

export default PageBuild;