import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { infoMenus } from '../ref/refINFO';

const ViewMainBlock = styled.div`
font-family: Pretendard;
width: 100%;
height: calc(100vh - 180px);
background-image: linear-gradient(to right, #F0F0F0, #FFFFFF);

#contentArea {
    padding: 50px 0 0 15%;
    > div:nth-child(1) {
        font-size: 2em;
        font-weight: bold;
        color: #434343;

        > span {
            color: #1E48A1;
            font-size: 1.5em;
            font-weight: bold;
        }
    }

    > div:nth-child(2) {
        font-size: 1.3em;
        font-weight: bold;
        color: #434343;
    }

    > div:nth-child(3) {
        padding: 20px 0;
        font-size: 1em;
        font-weight: bold;
        color: #434343;
    }

    > div:nth-child(4) {
        box-sizing: border-box;
        margin: 30px 0;

        > span {
            font-size: 1.2em;
            font-weight: bold;
            color: white;
            padding: 1em 2em;
            border-radius: 2em;
            background-image: linear-gradient(to right, #1E48A1, #0F2451);
            cursor: pointer;
        }
    }

    > div:nth-child(5) {
        box-sizing: border-box;
        margin-top: 80px;
    }
}

#imgMain1 {
    position: absolute;
    right: 0;
    bottom: 99px;
}
`;

const ViewMain = () => {
    const navigate = useNavigate();

    return (
        <ViewMainBlock>
            <div id='contentArea'>
                <div>우리 <span>다주소</span> 서비스는</div>
                <div>많은 주소의 각종 서류들을 한번에 처리해주는 서류 원스탑 자동발급 서비스입니다.</div>
                <div>
                    <div>등기부등본, 건축물대장, 공시가격 등의 다양한 정보를 발급 및 엑셀로 정리하여 드립니다.</div>
                    <div>고객이 필요한 항목을 추출하고 정리하여 편리하게 통계 등을 산출 할 수 있도록 엑셀 파일로 납품합니다.</div>
                </div>
                <div><span onClick={() => navigate(infoMenus[5].path)}>무료 견적받기 {'>'}</span></div>
                <div>
                    <img src="imgMain2.png" alt="Main2" />
                </div>
            </div>
            <img id='imgMain1' src="imgMain1.png" alt="Main1" />
        </ViewMainBlock>
    );
}

export default ViewMain;