import React from 'react';
import { infoMenus } from '../ref/refINFO';
import ViewCustom from '../view/ViewCustom';
import ViewBase from '../view/ViewBase';

const PageCustom = () => {

    //----------------------------------------------------------------- Working Function Area Start
    //----------------------------------------------------------------- Working Function Area End

    //----------------------------------------------------------------- useEffect Area Start
    //----------------------------------------------------------------- useEffect Area End

    //----------------------------------------------------------------- Header Button Area Start
    //----------------------------------------------------------------- Header Button Area End
    const propDatas = {
        curPath: infoMenus[3].path,
    }

    return (
        <>
            <ViewBase {...propDatas} ViewContainer={ViewCustom}/>
        </>
    );
}

export default PageCustom;