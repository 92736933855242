import React from 'react';
import styled from 'styled-components';

const ViewPriceBlock = styled.div`
font-family: Pretendard;
width: 100%;

#contentArea {
    width: 55%;
    margin: 0 auto;
}

#txtTitle {
    font-size: 2em;
    font-weight: bold;
    margin: 0.5em 0;
}

#cardArea {
    display: flex;
    justify-content: space-between;

    > div {
        width: 30em;
        height: 38em;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
}

.cardTitle {
    width: 100%;
    height: 4em;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;

    > div:nth-child(2) {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    > div:nth-child(3) {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
    }
}

.certiTitle {
    background-image: linear-gradient(to right, #9DB0F4, #1E48A1);
}

.buildTitle {
    background-image: linear-gradient(to right, #1BADAD, #2052BB);
}

.priceArea {
    margin: 1.5em 1em;
    box-sizing: border-box;
}

.txt1 {
    margin: 2em 0 0 0;
    box-sizing: border-box;
    > span {
        color: white;
        border-radius: 2em;
        box-sizing: border-box;
        padding: 0.5em 1em;
    }
}

.txt2 {
    box-sizing: border-box;
    margin: 1.5em 0 0 0.5em;
}

.txt3 {
    margin: 0.5em 0 0 1em;
    background-image: url('imgPass.png');
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 20px;
}

.txt4 {
    margin: 0.5em 0 0 1em;
}

.txt5 {
    margin: 1em 0;
    font-weight: bold;
    font-size: 1.2em;
    color: #2052BB;
}

.pointRed {
    color: red;
}
`;

const ViewPrice = () => {
    return (
        <ViewPriceBlock>
            <div id='contentArea'>
                <div id='txtTitle'>서비스 가격</div>
                <div id='cardArea'>
                    <div>
                        <div className='cardTitle certiTitle'>
                            <div></div>
                            <div>등기부등본</div>
                            <div>열람용(참고용)</div>
                        </div>
                        <div className='priceArea'>
                            <div className='txt1'><span className='certiTitle'>기본요금</span></div>
                            <div className='txt2'>지급수수료(등기소)</div>
                            <div className='txt3'>700원</div>
                            <div className='txt2'>업무대행료(PDF)</div>
                            <div className='txt3'>5,000건 이상 - 건당 400원</div>
                            <div className='txt3'>1,000건 이상 - 건당 500원</div>
                            <div className='txt3'>1,000건 미만 - 건당 600원</div>
                        </div>
                        <div className='priceArea'>
                        <div className='txt1'><span className='certiTitle'>선택요금</span></div>
                            <div className='txt2'>소유자명 / 면적 등의 추출정보 리스트(Excel)</div>
                            <div className='txt3'>건당 300원</div>
                            <div className='txt4'>* 고객이 원하는 추출정보에 따라 <span className='pointRed'>추가비용 발생</span></div>
                        </div>
                        <div className='priceArea'>
                            <div className='txt5'>제출서류</div>
                            <div className='txt4'>* 등기부등본상 소재지 목록 (당사 엑셀 양식)</div>
                            <div className='txt4'>* 건물이나 토지의 등기부등본 고유번호가 있는 경우 처리가 빠름</div>
                        </div>
                    </div>
                    <div>
                        <div className='cardTitle buildTitle'>
                            <div></div>
                            <div>건축물대장/토지대장</div>
                            <div>열람용(참고용)</div>
                        </div>
                        <div className='priceArea'>
                            <div className='txt1'><span className='buildTitle'>기본요금</span></div>
                            <div className='txt2'>업무대행료(PDF)</div>
                            <div className='txt3'>5,000건 이상 - 건당 400원</div>
                            <div className='txt3'>1,000건 이상 - 건당 500원</div>
                            <div className='txt3'>1,000건 미만 - 건당 600원</div>
                        </div>
                        <div className='priceArea'>
                        <div className='txt1'><span className='buildTitle'>선택요금</span></div>
                            <div className='txt2'>소유자명 / 면적 등의 추출정보 리스트(Excel)</div>
                            <div className='txt3'>건당 300원</div>
                            <div className='txt4'>* 고객이 원하는 추출정보에 따라 <span className='pointRed'>추가비용 발생</span></div>
                        </div>
                        <div className='priceArea'>
                            <div className='txt5'>제출서류</div>
                            <div className='txt4'>* 건축물대장/토지대장 소재지 목록 (당사 엑셀 양식)</div>
                        </div>
                    </div>
                </div>
            </div>
        </ViewPriceBlock>
    );
}

export default ViewPrice;