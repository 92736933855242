import React from 'react';
import { infoMenus } from '../ref/refINFO';
import ViewPrice from '../view/ViewPrice';
import ViewBase from '../view/ViewBase';

const PagePrice = () => {

    //----------------------------------------------------------------- Working Function Area Start
    //----------------------------------------------------------------- Working Function Area End

    //----------------------------------------------------------------- useEffect Area Start
    //----------------------------------------------------------------- useEffect Area End

    //----------------------------------------------------------------- Header Button Area Start
    //----------------------------------------------------------------- Header Button Area End
    const propDatas = {
        curPath: infoMenus[4].path,
    }

    return (
        <>
            <ViewBase {...propDatas} ViewContainer={ViewPrice}/>
        </>
    );
}

export default PagePrice;