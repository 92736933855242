import React from 'react';
import styled from 'styled-components';
import { infoMenus } from '../ref/refINFO';
import { useNavigate } from 'react-router-dom';

const ViewTopBarBlock = styled.div`
box-sizing: border-box;
width: 100%;
padding: 0 10%;
height: 80px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: white;
border-bottom: 1px solid #E0E0E0;

#logoArea {
    font-family: 'KBO Dia Gothic';
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    color: #213D6E;
    cursor: pointer;

    > img {
        // margin-right: 5px;
    }
}

#menuArea {
    display: flex;
    align-items: center;
    font-size: 1em;
    // font-weight: bold;

    > div {
        cursor: pointer;
        margin-left: 25px;

        > div:nth-child(2) {
            width: 50%;
            margin: 0 auto;
            height: 4px;
            margin-top: 5px;
            border-radius: 5px;
        }
    }
}

.curPath {
    color: #2052BB;
    font-weight: bold;

    > div:nth-child(2) {
        background-color: #2052BB;
    }
}

`;

const ViewTopBar = (props) => {
    const {
        curPath,
    } = props;

    const navigate = useNavigate();


    return (
        <ViewTopBarBlock>
                <div id='logoArea' onClick={() => navigate(infoMenus[0].path)}>
                    <img src="dajuso_logo.svg" alt="로고" width={64} height={64}/>
                    <div>다주소</div>
                </div>
                <div id='menuArea'>
                    {infoMenus.map((menu, index) => (
                        <div key={index} className={menu.path === curPath ? 'curPath' : ''} onClick={() => navigate(infoMenus[index].path)}><div>{menu.title}</div><div></div></div>
                    ))}
                </div>
        </ViewTopBarBlock>
    );
}

export default ViewTopBar;