import React from 'react';
import styled from 'styled-components';

const ViewBottomBarBlock = styled.div`
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100px;
background-color: #1A2F5B;
padding-top: 5px;
box-sizing: border-box;

> div:nth-child(1) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: 'KBO Dia Gothic';

    > div {
        display: flex;
        align-items: center;
        // justify-content: center;
    }
}

> div:nth-child(2) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    font-size: 1em;
    color: #9DB0F4;
    padding-left: 10px;
    margin-bottom: 5px;

    > div {
        padding: 0 5px;
    }
}

> div:nth-child(3) {
    width: 80%;
    margin: 0 auto;
    font-size: 0.8em;
    color: #5C719C;
    padding-left: 20px;
}
`;

const ViewBottomBar = () => {
    return (
        <ViewBottomBarBlock>
            <div>
                <img src="dajuso_logo.svg" alt="로고" width={40} height={40}/>
                <div>다주소</div>
            </div>
            <div>
                <div>우리종합서비스</div>
                <div>|</div>
                <div>사업자등록번호: 138-10-30088</div>
                <div>|</div>
                <div>대구광역시 달서구 와룡로24길 9-6</div>
                <div>|</div>
                <div>서울특별시 동대문구 장한로2길 20, 이레빌딩 802호</div>
                <div>|</div>
                <div>E-mail: manager@dajuso.com</div>
                <div>|</div>
                <div>TEL: 070-7675-7615</div>
            </div>
            <div>Copyright2024.WOORITS.All rights reserved.</div>
        </ViewBottomBarBlock>
    );
}

export default ViewBottomBar;