import React from 'react';
import styled from 'styled-components';

const ViewCustomBlock = styled.div`
width: 100%;

#backgroundBox {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 300px;

    background-image: linear-gradient(to right, #AD63EE, #1E48A1);
}

#boxContentArea {
    position: absolute;
    box-sizing: border-box;
    top: 80px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 50px 15%;

    > div:nth-child(1) {
        font-size: 2.5em;
        font-weight: bold;
        color: white;
    }
    > div:nth-child(2) {
        font-size: 1.2em;
        color: white;
        margin: 20px 0;
    }
    > div:nth-child(3) {
        font-size: 1.2em;
        display: flex;

        > div {
            width: 8em;
            height: 2.5em;
            border-radius: 2.5em;
            background-color: rgba(255, 255, 255, 0.3);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }
    }
}

#imgCust1 {
    position: absolute;
    top: 160px;
    left: 55%;
}

#imgCust2 {
    position: absolute;
    top: 190px;
    left: 75%;
}

#txtProcessArea {
    position: absolute;
    top: 430px;
    left: 15%;
    font-family: Pretendard;

    > div:nth-child(1) {
        display: flex;
        font-size: 1.5em;
        font-weight: bold;

        > div:nth-child(1) {
            width: 5px;
            background-color: #A862EB;
            margin-right: 10px;
            border-radius: 5px;
        }
    }

    > div:nth-child(2) {
        margin-top: 20px;
    }
}

#txtContent {
    display: flex;

    > img {
        margin-right: 40px;
    }

    > div {
        > div:nth-child(1) {
            font-size: 1.5em;
            font-weight: bold;
        }

        > div:nth-child(2) {
            margin-top: 10px;
            margin-bottom: 40px;
            font-size: 1.2em;

            > span {
                font-size: 1.2em;
                font-weight: bold;
                color: #A862EB;
            }
        }
    }
}

.txtConts {
    font-size: 1.1em;
    display: flex;
    margin: 15px 0;

    > img {
        margin-right: 10px;
    }
    > div {
        display: flex;
        align-items: center;
    }
}
`;

const ViewCustom = () => {
    return (
        <ViewCustomBlock>
            <div id='backgroundBox'></div>
            <div id='boxContentArea'>
                <div>고객 맞춤형 리포트</div>
                <div>고객이 원하는 데이터를 고객이 원하는 형식으로 다주소가 정리하여 리포팅 해드립니다.</div>
                <div>
                    <div>시간효율</div>
                    <div>적은비용</div>
                    <div>고객맞춤</div>
                </div>
            </div>
            <img id='imgCust1' src="imgCust1.png" alt="작업자" />
            <img id='imgCust2' src="imgCust2.png" alt="고객양식" />
            <div id='txtProcessArea'>
                <div>
                    <div></div>
                    <div>고객 맞춤형 리포트 서비스 제공</div>
                </div>
                <div id='txtContent'>
                    <img id='imgCust3' src="imgCust3.png" alt="엑셀샘플" />
                    <div>
                        <div>다주소만의</div>
                        <div><span>'고객 맞춤 커스터 마이징'</span>으로 편리하게 사용해보세요.</div>
                        <div className='txtConts'><img src="imgPass2.png" alt="체크2" /><div>원하는 데이터만 추출하여 사용합니다</div></div>
                        <div className='txtConts'><img src="imgPass2.png" alt="체크2" /><div>고객이 원하는 양식으로 자동 정리됩니다</div></div>
                        <div className='txtConts'><img src="imgPass2.png" alt="체크2" /><div>합계 / 평균 등의 데이터 산출이 용이합니다</div></div>
                    </div>
                </div>
            </div>
        </ViewCustomBlock>
    );
}

export default ViewCustom;