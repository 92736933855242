// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageMain from './page/PageMain';
import { infoMenus } from './ref/refINFO';
import PageCerti from './page/PageCerti';
import PageBuild from './page/PageBuild';
import PagePrice from './page/PagePrice';
import PageCustom from './page/PageCustom';
import PageEstimate from './page/PageEstimate';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PageMain       />} path={infoMenus[0].path} />
                <Route element={<PageCerti      />} path={infoMenus[1].path} />
                <Route element={<PageBuild      />} path={infoMenus[2].path} />
                <Route element={<PageCustom     />} path={infoMenus[3].path} />
                <Route element={<PagePrice      />} path={infoMenus[4].path} />
                <Route element={<PageEstimate   />} path={infoMenus[5].path} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
