import React from 'react';
import styled from 'styled-components';

const ViewEstimateBlock = styled.div`
font-family: Pretendard;
box-sizing: border-box;
width: 80%;
margin: 80px auto;
display: grid;
grid-template-columns: 1fr 1fr;
gap: 1px;
background-color: lightgray;

> div {
    background-color: white;

    > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div:nth-child(2) {
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        padding: 10px 0;
    }

    > div:nth-child(3) {
        width: 25em;
        font-size: 1em;
        margin: 0 auto;
        text-align: center;
    }

    > div:nth-child(4) {
        width: 20em;
        margin: 0 auto;
        margin-top: 2em;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1em 0;
            height: 2em;
            border-radius: 1.5em;
            font-size: 1.2em;
            font-weight: bold;
        }
    }
}

> div:nth-child(1) { > div:nth-child(2) { color: #7A42AA; } }
> div:nth-child(2) { > div:nth-child(2) { color: #1E48A1; } }
> div:nth-child(1) { > div:nth-child(4) { color: #7A42AA; > div { background-color: #E7E3FB; } } }
> div:nth-child(2) { > div:nth-child(4) { color: #1E48A1; > div { background-color: #CFD8EB; } } }
`;

const ViewEstimate = () => {
    return (
        <ViewEstimateBlock>
            <div>
                <div><img src="imgEstimate1.png" alt="궁금사항문의" /></div>
                <div>궁금사항 문의</div>
                <div>다주소 서비스에 대한 궁금하신 사항이나 견적관련 문의는 아래의 연락처로 언제든지 편하게 연락주세요</div>
                <div>
                    <div>TEL : 070-7675-7615</div>
                    <div>Phone : 010-8019-7615</div>
                    <div>E-mail: manager@dajuso.com</div>
                </div>
            </div>
            <div>
                <div><img src="imgEstimate2.png" alt="엑셀양식다운로드" /></div>
                <div>엑셀 양식 다운로드</div>
                <div>다주소 서비스를 이용하기 위한 엑셀 양식이 필요하시면 아래의 버튼을 클릭하여 다운로드 하시기 바랍니다</div>
                <div>
                    <div onClick={() => {
                        const fileUrl = 'DAJUSO-LIST.xlsx';

                        const link = document.createElement('a');
                        link.href = fileUrl;
                        link.setAttribute('download', 'DAJUSO-LIST.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    }} style={{ cursor: 'pointer' }}>양식파일다운로드</div>
                </div>
            </div>
        </ViewEstimateBlock>
    );
}

export default ViewEstimate;