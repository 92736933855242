import React from 'react';
import styled from 'styled-components';

const ViewCertiBlock = styled.div`
width: 100%;

#backgroundBox {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 300px;

    background-image: linear-gradient(to right, #9DB0F4, #2052BB);
}

#boxContentArea {
    position: absolute;
    box-sizing: border-box;
    top: 80px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 50px 15%;

    > div:nth-child(1) {
        font-size: 2.5em;
        font-weight: bold;
        color: white;
    }
    > div:nth-child(2) {
        font-size: 1.2em;
        color: white;
        margin: 20px 0;
    }
    > div:nth-child(3) {
        font-size: 1.2em;
        display: flex;

        > div {
            width: 8em;
            height: 2.5em;
            border-radius: 2.5em;
            background-color: rgba(255, 255, 255, 0.3);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }
    }
}

#imgPrint {
    position: absolute;
    top: 150px;
    left: 60%;
}

#imgCerti {
    position: absolute;
    top: 160px;
    left: 75%;
}

#txtProcessArea {
    position: absolute;
    top: 430px;
    left: 15%;
    font-family: Pretendard;

    > div:nth-child(1) {
        display: flex;
        font-size: 1.5em;
        font-weight: bold;

        > div:nth-child(1) {
            width: 5px;
            background-color: #9DB0F4;
            margin-right: 10px;
            border-radius: 5px;
        }
    }

    > div:nth-child(2) {
        margin: 5px 0 0 15px;
    }
}

#imgProcessArea {
    position: absolute;
    top: 500px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
`;

const ViewCerti = () => {
    return (
        <ViewCertiBlock>
            <div id='backgroundBox'></div>
            <div id='boxContentArea'>
                <div>다주소 등기부등본 처리</div>
                <div>다주소만의 등기부등본 발급 및 처리 서비스로 시간을 절약하고 많은 비용을 최소화하여 편리하게 이용해보세요.</div>
                <div>
                    <div>시간효율</div>
                    <div>적은비용</div>
                    <div>고객맞춤</div>
                </div>
            </div>
            <img id='imgPrint' src="imgPrint.png" alt="프린터" />
            <img id='imgCerti' src="imgCerti.png" alt="등기부등본" />
            <div id='txtProcessArea'>
                <div>
                    <div></div>
                    <div>등기부등본 처리 진행 5단계</div>
                </div>
                <div>다주소는 등기부등본 처리의 5단계 과정을 거쳐 진행됩니다.</div>
            </div>
            <div id='imgProcessArea'>
                <img src="imgProcessCerti.png" alt="등기부등본처리" />
            </div>
        </ViewCertiBlock>
    );
}

export default ViewCerti;