import React from 'react';
import ViewMain from '../view/ViewMain';
import { infoMenus } from '../ref/refINFO';
import ViewBase from '../view/ViewBase';

const PageMain = () => {

    //----------------------------------------------------------------- Working Function Area Start
    //----------------------------------------------------------------- Working Function Area End

    //----------------------------------------------------------------- useEffect Area Start
    //----------------------------------------------------------------- useEffect Area End

    //----------------------------------------------------------------- Header Button Area Start
    //----------------------------------------------------------------- Header Button Area End
    const propDatas = {
        curPath: infoMenus[0].path,
    }

    return (
        <>
            <ViewBase {...propDatas} ViewContainer={ViewMain}/>
        </>
    );
}

export default PageMain;