import React from 'react';
import styled from 'styled-components';
import ViewTopBar from './ViewTopBar';
import ViewBottomBar from './ViewBottomBar';

const ViewBaseBlock = styled.div``;

const ViewBase = (props) => {
    const {
        ViewContainer,
    } = props;
    return (
        <ViewBaseBlock>
            <ViewTopBar {...props}/>
            {ViewContainer ? <ViewContainer {...props}/> : null}
            <ViewBottomBar/>
        </ViewBaseBlock>
    );
}

export default ViewBase;